import React from "react";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import HeaderImage from "../Images/Home/Home.jpg";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.palette.background.paper,
  },
  paperCards: {
    marginBottom: theme.spacing(2),
  },
  Image: {
    width: "100%",
  },
}));

export default function Home() {
  const classes = useStyles();
  return (
    <>
      <Typography variant="h3" component="h1" gutterBottom align="center">
        <img src={HeaderImage} alt="Loading..." className={classes.Image} />
      </Typography>
      <Typography variant="h5" component="h1" gutterBottom align="center">
        <strong>Matthew 18:20</strong> <br />
        For where two or three gather in my name, there am I with them.
      </Typography>
      {/* <Typography variant="h5" component="h1" gutterBottom align="center">
        The son of God is delivered to men. They will kill him and after he is
        killed, he shall rise on the third day.
        <strong>Happy Good Friday 2020!</strong> May the mercy and grace of the
        Lord surround you and your family on this Good Friday
      </Typography> */}
    </>
  );
}
