import React from "react";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import HeaderImage from "../Images/Birthdays/HeaderImage.jpg";
import Divider from "@material-ui/core/Divider";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.palette.background.paper,
  },
  paperCards: {
    marginBottom: theme.spacing(3),
  },
  Image: {
    width: "100%",
  },
}));

export default function SpecialDays() {
  const classes = useStyles();
  return (
    <>
      <Typography variant="h3" component="h1" gutterBottom align="center">
        <img src={HeaderImage} alt="Loading..." className={classes.Image} />
      </Typography>
      <div className={classes.root}>
        <Paper elevation={2} className={classes.paperCards}>
          <Typography variant="h3" component="h2" gutterBottom align="center">
            January
          </Typography>
          <Divider />
          <Typography variant="h5" component="h2" gutterBottom align="center">
            Pam - 2nd
            <br />
            Denver & Michelle - Anniversary 4th
            <br />
            Isabelle - 8th
            <br />
            Fairlane - 17th
            <br />
            Michael & Penny - Anniversary 22nd
            <br />
            Ethan - 31st
            <br />
          </Typography>
        </Paper>

        <Paper elevation={2} className={classes.paperCards}>
          <Typography variant="h3" component="h2" gutterBottom align="center">
            February
          </Typography>
          <Divider />
          <Typography variant="h5" component="h2" gutterBottom align="center">
            Dayalan - 4th
            <br />
            Cayleen - 8th
            <br />
            Michelle - 9th
            <br />
            Faylin - 11th
            <br />
            Paris - 12th
            <br />
            Michael - 17th
            <br />
            Emma - 27th
          </Typography>
        </Paper>

        <Paper elevation={2} className={classes.paperCards}>
          <Typography variant="h3" component="h2" gutterBottom align="center">
            March
          </Typography>
          <Divider />
          <Typography variant="h5" component="h2" gutterBottom align="center">
            Pst Gregory & Vanessa - Anniversary 2nd
            <br />
            Venessa - 8th
            <br />
            Reena - 25th
          </Typography>
        </Paper>

        <Paper elevation={2} className={classes.paperCards}>
          <Typography variant="h3" component="h2" gutterBottom align="center">
            April
          </Typography>
          <Divider />
          <Typography variant="h5" component="h2" gutterBottom align="center">
            Melissa - 9th
            <br />
            Gwendolyn - 14th
            <br />
            Brett - 15th
            <br />
            Kiara - 28th
            <br />
            Mervin & Reena - Anniversary 21st
            <br />
            Andrew & Pam - Anniversary 29th
            <br />
          </Typography>
        </Paper>

        <Paper elevation={2} className={classes.paperCards}>
          <Typography variant="h3" component="h2" gutterBottom align="center">
            May
          </Typography>
          <Divider />
          <Typography variant="h5" component="h2" gutterBottom align="center">
            Caress - 12th
            <br />
            Penny - 13th
            <br />
            Anjeanique - 15th
            <br />
          </Typography>
        </Paper>

        <Paper elevation={2} className={classes.paperCards}>
          <Typography variant="h3" component="h2" gutterBottom align="center">
            June
          </Typography>
          <Divider />
          <Typography variant="h5" component="h2" gutterBottom align="center">
            Estelle - 2nd
            <br />
            Edwin & Kubash - Anniversary 6th
            <br />
            Claude & Gwen - Anniversary 11th
            <br />
            Edwin - 14th
            <br />
            Gerard - 25th
            <br />
            Allison Jade - 30th
          </Typography>
        </Paper>

        <Paper elevation={2} className={classes.paperCards}>
          <Typography variant="h3" component="h2" gutterBottom align="center">
            July
          </Typography>
          <Divider />
          <Typography variant="h5" component="h2" gutterBottom align="center">
            Pastor Greg - 8th
            <br />
            Andrea - 9th
            <br />
            Selven & Denise - Anniversary 19th
            <br />
            Denise - 28th
            <br />
          </Typography>
        </Paper>

        <Paper elevation={2} className={classes.paperCards}>
          <Typography variant="h3" component="h2" gutterBottom align="center">
            August
          </Typography>
          <Divider />
          <Typography variant="h5" component="h2" gutterBottom align="center">
            Mervin - 1st
            <br />
            Andrew - 2nd
            <br />
            Allison B - 8th
            <br />
            Kubashni - 21st
            <br />
            Denver - 31st
          </Typography>
        </Paper>

        <Paper elevation={2} className={classes.paperCards}>
          <Typography variant="h3" component="h2" gutterBottom align="center">
            September
          </Typography>
          <Divider />
          <Typography variant="h5" component="h2" gutterBottom align="center">
            Ariyana - 1st
            <br />
            Cameron - 4th
            <br />
            Flintston - 15th
            <br />
            John - 15th
            <br />
            Selven - 18th
            <br />
          </Typography>
        </Paper>

        <Paper elevation={2} className={classes.paperCards}>
          <Typography variant="h3" component="h2" gutterBottom align="center">
            October
          </Typography>
          <Divider />
          <Typography variant="h5" component="h2" gutterBottom align="center">
            <br />
          </Typography>
        </Paper>

        <Paper elevation={2} className={classes.paperCards}>
          <Typography variant="h3" component="h2" gutterBottom align="center">
            November
          </Typography>
          <Divider />
          <Typography variant="h5" component="h2" gutterBottom align="center">
            Kian - 19th
            <br />
          </Typography>
        </Paper>

        <Paper elevation={2} className={classes.paperCards}>
          <Typography variant="h3" component="h2" gutterBottom align="center">
            December
          </Typography>
          <Divider />
          <Typography variant="h5" component="h2" gutterBottom align="center">
            Claude - 3rd
            <br />
            Caleb - 5th
            <br />
            Matthew - 28th
            <br />
          </Typography>
        </Paper>
      </div>
    </>
  );
}
