import {
  Card,
  CardMedia,
  Dialog,
  DialogTitle,
  IconButton,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
const SimpleDialog = (props) => {
  const { onClose, open } = props;

  const [name, setName] = useState("");
  const [easterMeaning, setEasterMeaning] = useState("");
  const [question1, setQuestion1] = useState(false);
  const [question2, setQuestion2] = useState(false);
  const [quizComplete, setQuizComplete] = useState(false);

  const handleClose = () => {
    onClose();
  };

  const checkQuestion1 = (event) => {
    const answer = "Luke";

    if (event.target.value.toLowerCase() === answer.toLowerCase()) {
      setQuestion1(true);
      return;
    }
    setQuestion1(false);
    return;
  };

  const checkQuestion2 = (event) => {
    const answer = "Cross";

    if (event.target.value.toLowerCase() === answer.toLowerCase()) {
      setQuestion2(true);
      return;
    }
    setQuestion2(false);
    return;
  };

  const checkQuestion3 = (event) => {
    const answer = "Patmos Fellowship";

    if (event.target.value.toLowerCase() === answer.toLowerCase()) {
      setQuizComplete(true);
      return;
    }
    setQuizComplete(false);
    return;
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      {!quizComplete ? (
        <div style={{ padding: "25px" }}>
          <DialogTitle id="simple-dialog-title" onClose={handleClose}>
            Congratulations you are almost there!!{"       "}
            <IconButton aria-label="close" onClick={handleClose}>
              X
            </IconButton>
          </DialogTitle>
          <Typography variant="subtitle1" align="left">
            Enter your Name
          </Typography>
          <h1></h1>
          <TextField
            id="outlined-basic"
            label="Enter your name"
            variant="outlined"
            fullWidth
            onChange={(event) => setName(event.target.value)}
          />
          <h1></h1>

          <Typography variant="subtitle1" align="left">
            What does Easter mean to you?
          </Typography>
          <h1></h1>
          <TextField
            id="outlined-basic"
            label="The meaning of Easter"
            variant="outlined"
            fullWidth
            onChange={(event) => setEasterMeaning(event.target.value)}
          />
          <h1></h1>

          <Typography variant="subtitle1" align="left">
            In which book will you find this verse : "He is not here, He has
            risen!"
          </Typography>
          <h1></h1>
          <TextField
            id="outlined-basic"
            label="He is not here, He has risen!"
            variant="outlined"
            fullWidth
            onChange={(value) => checkQuestion1(value)}
          />
          <h1></h1>

          {question1 && (
            <>
              <Typography variant="subtitle1" align="left">
                Jesus was crucified on a .........
              </Typography>
              <h1></h1>
              <TextField
                id="outlined-basic"
                label="Jesus was crucified on a ........."
                variant="outlined"
                fullWidth
                onChange={(value) => checkQuestion2(value)}
              />
              <h1></h1>
            </>
          )}

          {question2 && (
            <>
              <Typography variant="subtitle1" color="primary" align="left">
                What is the name of our church?
              </Typography>
              <h1></h1>
              <TextField
                id="outlined-basic"
                label="Church Name"
                variant="outlined"
                fullWidth
                onChange={(value) => checkQuestion3(value)}
              />
              <h1></h1>
            </>
          )}
        </div>
      ) : (
        <div style={{ padding: "25px" }}>
          <DialogTitle id="simple-dialog-title" onClose={handleClose}>
            <h3 align="center">{`Congratulations ${name}`}</h3>
            <h3 align="center">{`Egg hunt completed!!`}</h3>
            <p align="center">{`${easterMeaning}`}</p>
          </DialogTitle>

          <Card
            style={{
              maxWidth: 600,
              boxShadow: "0 5px 8px 0 rgba(0, 0, 0, 0.3)",
              backgroundColor: "#fafafa",
              align: "center",
            }}
          >
            <CardMedia
              style={{ height: "300px" }}
              align="center"
              image={
                "https://www.quoteswishesmsg.com/wp-content/uploads/2020/02/easter-day-images-min.jpg"
              }
            />
          </Card>
          <h1></h1>
          {`Date completed :  ${new Date()} `}
        </div>
      )}
    </Dialog>
  );
};

export default SimpleDialog;
