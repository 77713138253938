import React from "react";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.palette.background.paper,
  },
  paperCards: {
    marginBottom: theme.spacing(2),
  },
}));

export default function Worship() {
  const classes = useStyles();

  const IFrameSetup = (url) => {
    return (
      <iframe
        title="Loading...."
        frameBorder="0"
        src={url}
        enablecastapi="true"
        allowfullscreen="true"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      >
        Loading .....
      </iframe>
    );
  };
  return (
    <>
      <Typography variant="h3" component="h1" gutterBottom align="center">
        Worship Songs
      </Typography>
      <div className={classes.root}>
        <Paper elevation={2} className={classes.paperCards}>
          <Typography variant="h5" component="h2" gutterBottom align="center">
            Patmos Fellowship - Cameron
            <br />
            Gospel Acoustic Medley
            <br />
            Guess the songs and win a prize!!!
          </Typography>
          <Typography variant="h5" component="h2" gutterBottom align="center">
            <audio controls>
              <source
                src="http://patmos.businessprovider.co.za/CamoGuitarAccoustic.mpeg"
                type="audio/mpeg"
              />
              Your browser does not support the audio element.
            </audio>
          </Typography>
        </Paper>
        <Paper elevation={2} className={classes.paperCards}>
          <Typography variant="h5" component="h2" gutterBottom align="center">
            We The Kingdom & Tasha Cobbs – Holy Water
          </Typography>
          <Typography variant="h5" component="h2" gutterBottom align="center">
            {IFrameSetup("https://www.youtube.com/embed/7KLQ2AXQmtA")}
          </Typography>
        </Paper>
        <Paper elevation={2} className={classes.paperCards}>
          <Typography variant="h5" component="h2" gutterBottom align="center">
            The Blessing By Kari Jobe & Cody Carnes
          </Typography>
          <Typography variant="h5" component="h2" gutterBottom align="center">
            {IFrameSetup("https://www.youtube.com/embed/Zp6aygmvzM4")}
          </Typography>
        </Paper>
        <Paper elevation={2} className={classes.paperCards}>
          <Typography variant="h5" component="h2" gutterBottom align="center">
            Casting Crowns -Great Are You Lord
          </Typography>
          <Typography variant="h5" component="h2" gutterBottom align="center">
            {IFrameSetup("https://www.youtube.com/embed/FaP8fKAR2Og")}
          </Typography>
        </Paper>

        <Paper elevation={2} className={classes.paperCards}>
          <Typography variant="h5" component="h2" gutterBottom align="center">
            Casting Crowns - At Calvary Live
          </Typography>
          <Typography variant="h5" component="h2" gutterBottom align="center">
            {IFrameSetup("https://www.youtube.com/embed/5hA1xMB_3-A")}
          </Typography>
        </Paper>

        <Paper elevation={2} className={classes.paperCards}>
          <Typography variant="h5" component="h2" gutterBottom align="center">
            Matt Redman - 10 000 Reasons
          </Typography>
          <Typography variant="h5" component="h2" gutterBottom align="center">
            {IFrameSetup("https://www.youtube.com/embed/XtwIT8JjddM")}
          </Typography>
        </Paper>

        <Paper elevation={2} className={classes.paperCards}>
          <Typography variant="h5" component="h2" gutterBottom align="center">
            Raise a Hallelujah - Bethel Music
          </Typography>
          <Typography variant="h5" component="h2" gutterBottom align="center">
            {IFrameSetup("https://www.youtube.com/embed/_M5iR8qvl4M")}
          </Typography>
        </Paper>
        <Paper elevation={2} className={classes.paperCards}>
          <Typography variant="h5" component="h2" gutterBottom align="center">
            Graves into Garden's - Elevation Music
          </Typography>
          <Typography variant="h5" component="h2" gutterBottom align="center">
            {IFrameSetup("https://www.youtube.com/embed/KwX1f2gYKZ4")}
          </Typography>
        </Paper>
        {/* <Paper elevation={2} className={classes.paperCards}>
          <Typography variant="h5" component="h2" gutterBottom align="center">
            Patmos Worship
          </Typography>
          <Typography variant="h5" component="h2" gutterBottom align="center">
            <video width="100%" controls>
              <source
                src="http://patmos.businessprovider.co.za/Goodness.mp4"
                type="video/mp4"
              />
              Your browser does not support HTML5 video.
            </video>
          </Typography>
        </Paper> */}
      </div>
    </>
  );
}
