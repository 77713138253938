import React from "react";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import HeaderImage from "../Images/GoodFriday/GF.jpg";
import EasterSunday from "../Images/EasterSunday/EasterSunday.jpg";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.palette.background.paper,
  },
  paperCards: {
    marginBottom: theme.spacing(2),
  },
  Image: {
    width: "100%",
  },
}));

export default function EasterFriday() {
  const classes = useStyles();
  const IFrameSetup = (url) => {
    return (
      <iframe
        title="Loading...."
        frameBorder="0"
        src={url + "?autoplay=true&enablejsapi=true&fs=1&enablecastapi=true"}
        enablecastapi="true"
        allowfullscreen="true"
      >
        Loading .....
      </iframe>
    );
  };
  return (
    <>
      <Typography variant="h3" component="h1" gutterBottom align="center">
        <img src={EasterSunday} alt="Loading..." className={classes.Image} />
      </Typography>
      <div className={classes.root}>
        <Paper elevation={2} className={classes.paperCards}>
          <Typography variant="h5" component="h2" gutterBottom align="center">
            Easter Sunday - Medley
          </Typography>
          <Typography variant="h5" component="h2" gutterBottom align="center">
            {IFrameSetup("https://www.youtube.com/embed/f26P1ftbCco")}
          </Typography>
        </Paper>
        <Paper elevation={2} className={classes.paperCards}>
          <Typography variant="h5" component="h2" gutterBottom align="center">
            Easter Sunday
          </Typography>
          <Typography variant="h6" component="h3" gutterBottom align="center">
            Date : 12 April 2020
          </Typography>
          <Typography variant="h5" component="h2" gutterBottom align="center">
            {
              <iframe
                src="https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2F255491817968821%2Fvideos%2F3240032289342763%2F&show_text=0&width=315"
                width="315"
                height="315"
                frameBorder="0"
                scrolling="no"
                frameborder="0"
                allowTransparency="true"
                allowFullScreen="true"
              ></iframe>
            }
          </Typography>
        </Paper>
      </div>
      <Typography variant="h3" component="h1" gutterBottom align="center">
        <img src={HeaderImage} alt="Loading..." className={classes.Image} />
      </Typography>
      <div className={classes.root}>
        <Paper elevation={2} className={classes.paperCards}>
          <Typography variant="h5" component="h2" gutterBottom align="center">
            Good Friday - Easter Video
          </Typography>
          <Typography variant="h5" component="h2" gutterBottom align="center">
            {IFrameSetup("https://www.youtube.com/embed/-blALAEijZI")}
          </Typography>
        </Paper>
        <Paper elevation={2} className={classes.paperCards}>
          <Typography variant="h5" component="h2" gutterBottom align="center">
            Good Friday
          </Typography>
          <Typography variant="h6" component="h3" gutterBottom align="center">
            Date : 10 April 2020
          </Typography>
          <Typography variant="h5" component="h2" gutterBottom align="center">
            {
              <iframe
                src="https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2F255491817968821%2Fvideos%2F528825648066494%2F&show_text=0&width=267"
                width="267"
                height="476"
                frameBorder="0"
                scrolling="no"
                frameborder="0"
                allowTransparency="true"
                allowFullScreen="true"
              ></iframe>
            }
          </Typography>
        </Paper>
      </div>
    </>
  );
}
